<template>
  <div class="sortingform">
    <a-form-model layout="inline" class="sortingForm">
      <a-form-model-item class="itemFormItem">
        <a-button class="back-btn bgBlue" size="large" type="primary" @click="go_back">
          <a-icon type="arrow-left"/>
          返回
        </a-button>
      </a-form-model-item>
      <a-form-model-item class="itemFormItem">
        <a-input-search placeholder="输入用户名称" @change="changedata" v-model="dataObj.user_name"
                        style="width:200px;margin-left:10px"/>
      </a-form-model-item>
      <a-form-model-item class="itemFormItem">
        <a-button class="back-btn bgBlue" @click="() => {$emit('filter')}">筛选</a-button>
      </a-form-model-item>

      <a-form-model-item class="itemFormItem">
        <a-button class="back-btn bgBlue" size="large" type="primary" @click="searchdata">
          查询
        </a-button>
      </a-form-model-item>

      <a-form-model-item class="itemFormItem">
        <a-button class="print-btn" size="large" @click="$emit('printing')">
          一键打印
        </a-button>
      </a-form-model-item>

      <a-form-model-item class="itemFormItem sort-btn-form">
        <div class="bgBlue" style="display: flex; align-items: center">
          <a-button class="sorting-btn" style="width: 119px" @click="$emit('sorting')">一键分拣</a-button>
          <div class="line"/>
          <a-popover placement="bottom">
            <a-button
                    style="
                                font-size: 20px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #1E3B98;
                                border: none;
                                width: 160px;
                                height: 60px;" slot="content" class="popover-btn" @click="coverAllsort">
              一键恢复
            </a-button>
            <a-button class="sorting-sub-btn" style="width: 56px" icon="down"></a-button>
          </a-popover>
        </div>
        <!--                <a-button class="query-btn bgBlue" size="large" type="primary" @click="$emit('sorting')">-->
        <!--                    一键分拣-->
        <!--                </a-button>-->
      </a-form-model-item>
    </a-form-model>

    <a-drawer
            :width="640"
            placement="right"
            :closable="false"
            :visible="coversortShow"
            mask
            @close="coversortShow=false"
            class="drawBoxcommon"
    >

      <div>
        <div class="title">
          一键恢复
          <a-icon type="close-circle" class="close" @click="coversortShow=false"/>
        </div>
        <a-table :data-source="Datarecovery" :columns="tabrecovery" :pagination="paginationvery"
                 :loading="tableLoadingone">
          <template slot="created_at" slot-scope="text, record">
            <span>{{timeConvertData(record.created_at)}}</span>
          </template>
          <template slot="active" slot-scope="text, record">
            <a-popconfirm title="确定恢复成待分拣?" cancel-text="取消" ok-text="确定" @confirm="() => upDatavery(record.id)">
              <a-button type="primary" size="small" style="background:#203EA0;border-color:#203EA0">
                一键恢复
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>


    </a-drawer>
  </div>
</template>

<script>
  import {PrintAccount} from '@/assets/js/doPrint'
  import {timeConvert} from '@/assets/js/common'

  export default {
    name: 'out_stock_form',
    props: {
      data: {}
    },
    data() {
      return {
        dataObj: {},
        coversortShow: false,
        Datarecovery: [],
        paginationvery: {
          total: 0,
          pageSize: 10,
          showTotal: total => `共 ${total} 条`
        },
        tableLoadingone: false,
        tabrecovery: [
          {title: 'ID', dataIndex: 'id', key: 'id'},
          {title: '商品名称', dataIndex: 'product_name', key: 'product_name', width: 130, ellipsis: true},
          {title: '分拣员', dataIndex: 'admin.name', key: 'admin.name', width: 80, ellipsis: true},
          {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 160,
            ellipsis: true,
            scopedSlots: {customRender: 'created_at'}
          },
          {title: '操作', dataIndex: 'active', key: 'active', scopedSlots: {customRender: 'active'}},
        ]
      }
    },
    created() {
      this.dataObj = this.data;
    },
    watch: {
      data: function (a, b) {
        this.dataObj = a
      },
    },
    methods: {
      upDatavery(id) {
        this.tableLoadingone = true;
        this.rq.get('/merapi/sorting/v2/sorting/restore?id=' + id).then(res => {
          this.getArecovery()
          this.$emit('fresh')
          this.$message.success('恢复成功。');
        }).catch(err => {
          this.tableLoadingone = false;
        })
      },
      timeConvertData(e) {
        return timeConvert(e, 1)
      },
      coverAllsort() {
        this.coversortShow = true;
        this.getArecovery();
      },
      getArecovery() {
        this.tableLoadingone = true;
        this.rq.get('/merapi/sorting/v2/sorting/restore-list').then(res => {
          this.tableLoadingone = false;
          this.Datarecovery = res.data.data;
        }).catch(err => {
          this.tableLoadingone = false;
        })
      },
      go_back() {
        this.$router.back()
      },
      onSearch() {

      },
      searchdata() {
        this.$emit('search', this.dataObj)
      },
      changedata(e) {
        this.$emit('change', this.dataObj)
      }
    }
  }
</script>
<style>
  .sortingForm .ant-input {
    height: 80px !important;
    font-size: 20px
  }

  .sortingForm .ant-input-search-icon {
    font-size: 20px
  }
</style>
<style scoped lang="less">
  .drawBoxcommon {
    .title {
      font-size: 23px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 32px;
      letter-spacing: 1px;
      position: relative;
      padding: 30px 0px;

      .close {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 36px;
      }
    }
  }

  .sortingform {
    background: #fff;
    display: flex;
    padding: 25px;
    align-items: center;
    width: 82.3%;
  }

  .back-btn {
    padding: 0px 20px;
    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
    border-radius: 5px;
    border: none;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    height: 80px;
    outline: none;
  }

  .query-btn {
    width: 97px;
  }

  .logo-top-img {
    width: 16%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 2px 11px 0px rgb(177 188 199 / 15%);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-img {
      width: 77.5%;

    }
  }

  .bgBlue {
    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
    border-radius: 5px;
    border: none;
    height: 80px;
    outline: none;
  }

  .print-btn {
    border-radius: 5px;
    height: 80px;
    outline: none;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #203EA0;
    margin-left: 30px;
    font-size: 20px;
    width: 120px;
  }

  .itemFormItem {
    height: 80px;

    &.sort-btn-form {
      width: 177px;
    }

    .sorting-sub-btn, .sorting-btn {
      height: 100%;
      background-color: transparent;
      border: none;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }

    .line {
      width: 2px;
      height: 20px;
      background: rgba(250, 250, 250, 0.5);
    }

    .sorting-btn {
      width: 120px;
    }

    .sorting-sub-btn {
      width: 56px;
    }

    .sorting-btn:active {
      box-shadow: none !important;
    }

    .sorting-sub-btn:active {
      box-shadow: none !important;
    }

    /deep/ .popover-btn {
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1E3B98;
      width: 160px;
      height: 60px;
    }
  }
</style>
