<template>
  <div class="out-stock-container">

    <div class="header">
      <div style="width: 205px">
        <logotop></logotop>
      </div>
      <sortingform
          style="margin-left:0.7%"
          class="sortingformbox"
          :data="staticdata"
          @change="changeformData"
          @filter="filterAction"
          @search="searchformData"
          @printing="printAll"
          @sorting="showBatchSortConfirm"
          @fresh="getData('refresh')"
      ></sortingform>
    </div>
    <a-spin :spinning="loading">
      <!--  <div>下单数量:<span>{{othertotal.total_order_sum}}</span></div>
        <div>已分拣数量: <span style="color: green;">{{othertotal.total_sorting_sum}}</span></div>
        <div>未分拣:<span style="color: red;">{{othertotal.total_residue_sum}}</span></div>
        <div>分拣差异数量:<span style="color: blue;">{{othertotal.total_sorting_diff_sum}}</span>(说明：下单数量-已分拣数量)</div> -->
      <div class="soring-info-view">
        <div class="info-item">
          <span class="info-item-title">分拣商品：</span>
          <span class="info-item-value">{{optionsData.product_name}}</span>
        </div>
        <div class="info-item">
          <span class="info-item-title">下单数：</span>
          <span class="info-item-value">{{otherData.total_order_sum}}</span>
          <span class="info-item-value-unit">{{optionsData.unit}}</span>
        </div>
        <div class="info-item">
          <span class="info-item-title">已分拣数：</span>
          <span class="info-item-value">{{otherData.total_sorting_sum}}</span>
          <span class="info-item-value-unit">{{optionsData.unit}}</span>
        </div>
        <div class="info-item">
          <span class="info-item-title">未分拣：</span>
          <span class="info-item-value">{{otherData.total_residue_sum}}</span>
          <span class="info-item-value-unit" style="color: #BC1D00">{{optionsData.unit}}</span>
        </div>
        <!-- <div class="info-item">
            <span class="info-item-title">分拣差异数量：</span>
            <span class="info-item-value">{{otherData.total_sorting_diff_sum}}</span>
            <span class="info-item-value-unit" style="color: #BC1D00">斤</span>
        </div> -->
      </div>
      <a-empty class="nodataempty" v-if="datalist.length==0">
        <span slot="description">暂无数据</span>
      </a-empty>
      <div class="sorting-detail-view" @scroll="listenScroll" v-if="datalist.length!==0">
        <a-row :gutter="10">
          <a-col class="gutter-row" :span="6" v-for="(item,index) in datalist" :key="index"
                 @click="sortingAction(item)">
            <div class="sorting-detail-item" :class="[item.note ? 'item-border' : '', 'border-' + item.sorting_status]">
              <div class="detail-item-header">
                <div class="item-status">
                  <div :class="'status-' + item.sorting_status"/>
                  {{item.sorting_status_name}}
                </div>
                <div class="out-sign" v-if="item.stock_status==0">缺</div>
              </div>
              <div class="entity-name-container" :ref="'entity-name-container' + index">
                <div class="entity-name" :ref="'entity-name' + index" :style="{'transform': item.client.nameScale}">
                  {{item.client.name}}
                </div>
              </div>

              <div class="mark-view">
                <div class="mark-text">{{item.note}}</div>
              </div>
              <div class="num-info">
                <div>
                  <span>订购：</span>
                  <span>{{item.order_sum}}{{item.base_unit}}</span>
                </div>
                <div>
                  <span>称重：</span>
                  <span>{{item.sorting_sum || '0'}}{{item.base_unit}}</span>
                </div>
              </div>
              <div class="border-line" :class="'statusBg-' + item.sorting_status"/>
            </div>
          </a-col>

        </a-row>
        <load-more :status="loadingType" style="margin-top:10px"></load-more>
      </div>
      <sortingOperateDialog :visible="dialogVisible" @change="dialogVisibleClick"/>

    </a-spin>


    <div class="sortPrint" @click="hasCheckPrintChange">
           <span class="radioStyle" :class="has_check_print?'nochoseClick':'choseClick'">
               <a-icon type="check"/>
           </span>
      <span>分拣时不打印标签 <a-tooltip placement="left">
		                    <template slot="title">
		                        <span>开启后，分拣时不打印分拣小票</span>
		                    </template>
		                    <a-icon type="exclamation-circle"/>
		                </a-tooltip></span>
    </div>

    <a-drawer
        v-if="filterAllData"
        :width="640"
        placement="right"
        :closable="false"
        :visible="filterVisible"
        mask
        @close="filterClose"
    >
      <div slot="title" class="drawer-title-view">
        <div class="title">高级筛选</div>
        <div class="btns">
          <a-button class="title-btn" @click="filterSaveAction">保存</a-button>
          <a-button class="title-btn" @click="filterResetAction">重置</a-button>
        </div>
      </div>

      <div class="form" style="margin-top: 20px">
        <a-row class="input-row">
          <a-col :span="12">
            <div class="form-title">客户名称：</div>
            <a-input v-model="filterForm.user_name" clearable class="form-input" placeholder="客户名称"/>
          </a-col>
        </a-row>

        <a-row class="input-row" :gutter="47">
          <a-col :span="12">
            <div class="form-title">分拣状态：</div>
            <a-select v-model="filterForm.sorting_status" class="form-input" placeholder="分拣状态">
              <a-select-option v-for="item in filterAllData.sortStatusList" class="option-class" :value="item.id"
                               :key="item.id">
                {{item.name}}
              </a-select-option>

            </a-select>
          </a-col>

          <a-col :span="12">
            <div class="form-title">装车</div>
            <a-select v-model="filterForm.box_status" class="form-input" placeholder="装车">
              <a-select-option v-for="item in filterAllData.zxList" :key="item.id" class="option-class"
                               :value="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>

        <a-row class="input-row" :gutter="47">
          <a-col :span="12">
            <div class="form-title">区域：</div>
            <a-select v-model="filterForm.zone_id" class="form-input" placeholder="区域">
              <a-select-option v-for="item in filterAllData.areaList" :key="item.id" class="option-class"
                               :value="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-col>

          <a-col :span="12">
            <div class="form-title">供应商：</div>
            <a-select class="form-input" v-model="filterForm.merchant" placeholder="供应商">
              <a-select-option v-for="item in filterAllData.supplyList" :key="item.id" class="option-class"
                               :value="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>

        <a-row class="input-row" :gutter="47">
          <a-col :span="12">
            <div class="form-title">线路：</div>
            <a-select class="form-input" v-model="filterForm.line_id" placeholder="线路">
              <a-select-option v-for="item in filterAllData.lineList" class="option-class" :value="item.id"
                               :key="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-col>

          <a-col :span="12">
            <div class="form-title">仓库</div>
            <a-select class="form-input" v-model="filterForm.warehouse" placeholder="仓库">
              <a-select-option v-for="item in filterAllData.box_status" class="option-class" :value="item.id"
                               :key="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>

        <a-row class="input-row" :gutter="47">
          <a-col :span="12">
            <div class="form-title">是否缺货：</div>
            <a-select class="form-input" v-model="filterForm.stock_status" placeholder="是否缺货">
              <a-select-option v-for="item in filterAllData.stockList" class="option-class" :value="item.id"
                               :key="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-col>

          <a-col :span="12">
            <div class="form-title">是否备注：</div>
            <a-select class="form-input" v-model="filterForm.order_commodity_remark" placeholder="是否备注">
              <a-select-option v-for="item in filterAllData.commodity_remark" class="option-class" :value="item.id"
                               :key="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </div>


    </a-drawer>
  </div>
</template>

<script>
  import logotop from "@/components/logotop"; //顶部logo
  import sortingform from "@/components/out_stock_form"; //顶部查询
  import sortingOperateDialog from '@/components/sorting_dialog'
  import {PrintAccount, CheckIsP} from '@/assets/js/doPrint'
  import loadMore from "@/components/loadMore"; //顶部查询
  export default {
    name: "out_stock",
    components: {
      logotop,
      sortingform,
      sortingOperateDialog,
      loadMore
    },
    data() {
      return {
        data: [
          {
            status: 1,
            out: false,
          },
          {
            status: 2,
            out: false,
          },
          {
            status: 3,
            out: false,
          },
          {
            status: 4,
            out: true,
          }
        ],
        batch: { // 批量操作
          print: [], // 批量打印id
          sort: [], // 批量分拣id
        },
        oldUrl: '',
        dialogVisible: false,
        LOCK_STATUS: false,
        otherData: {},
        loadingType: 'loading',
        loading: false,
        datalist: [],
        has_check_print: true,
        optionsData: {
          product_name: '',
          commodity_name: '',
          unit: '',
          send_time: ''
        },
        printUrl: '',
        staticdata: {
          user_name: '',
          send_time: '',
          page_size: 16,
          commodity_name: '',
          page: 1,

        },
        filterVisible: false,
        filterAllData: null,
        filterForm: {}
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.oldUrl = from.path
      })
    },
    activated() {
      this.optionsData = this.$route.query
      this.staticdata.commodity_name = this.optionsData.commodity_name;
      this.staticdata.send_time = this.optionsData.send_time;
      this.staticdata.sku_id = this.optionsData.sku_id
      if (this.oldUrl = '/sorting') {
        this.staticdata.user_name = '';
      }
      this.getPrintStatus();//获取是否打印标签
      this.getData('refresh')

    },
    mounted() {
      this.getRrintUrl();
      this.getclassfiy();
    },

    methods: {
      getclassfiy() {
        this.rq.get('/merapi/sorting/v2/common/code-list').then(res => {
          this.filterAllData = res.data
          // this.calleArr(this.classifylist)
        })
      },
      filterAction() {
        console.log('xxx')
        this.filterVisible = true
      },
      filterClose() {
        this.filterVisible = false
      },
      filterSaveAction() {
        this.filterVisible = false
        this.getData('refresh')
      },
      filterResetAction() {
        this.filterForm = {}
        this.filterVisible = false
        this.getData('refresh')
      },
      getRrintUrl(e) {
        this.loading = true;
        this.rq.get('/merapi/sorting/v2/print/get-print-url').then(res => {
          for (let item of res.data) {
            if (item.key == 'print_tag') {
              this.printUrl = item.host + item.data[0].url;
            }
          }
        }).catch(err => {
        })
      },
      getPrintStatus() {
        this.rq.get('/merapi/sorting/v2/sorting/get-tag-print-status').then(res => {
          console.log(res.data)
          if (res.data.status === 0) {
            this.has_check_print = false;
          } else {
            this.has_check_print = true;
          }
        })
      },
      hasCheckPrintChange(e) { // 是否打印标签
        this.has_check_print = !this.has_check_print
        var status = this.has_check_print ? 1 : 0;
        var param = {
          status: status
        }
        this.rq.post('/merapi/sorting/v2/sorting/update-tag-print-status', param).then(res => {
          console.log('打印标识已传入后台')
        })
      },
      changeformData(e) {
        this.staticdata = e
      },
      printAll() {
        if (!CheckIsP()) {
          return;
        }
        var that = this;
        var param = that.batch.print;
        if (param.length == 0) {
          that.$notification['error']({
            message: '温馨提示',
            description: '可用打印选项为空~',
          });
          return;
        }
        that.$confirm({
          title: '确认',
          content: h => `将有${param.length}个标签被打印（注意：此功能会打印所有标签包括所有已分拣和未分拣的）确定打印？`,
          okText: '确定',
          okType: 'info',
          cancelText: '取消',
          onOk() {
            if (param.length > 0) {
              param = JSON.stringify(param);
              param = {item: param};
              that.rq.post('/merapi/sorting/v2/sorting/batch-print', param).then(res => {
                var data = res.data;
                for (var i = 0; i < data.length; i++) {
                  var url = that.printUrl + '?' + data[i]['url'] + '&type=tag'
                  console.log(url)
                  PrintAccount('批量打印', url, 'print')
                }
              })


            }
          },
          onCancel() {

          },
          class: 'test',
        });
      },
      // 这是一键分拣
      showBatchSortConfirm() {
        var that = this;
        var param = that.batch.sort;
        if (that.has_check_print) {
          if (!CheckIsP()) {
            return;
          }
        }
        if (param.length == 0) {
          that.$notification['error']({
            message: '温馨提示',
            description: '可用分拣选项为空~',
          });
          return;
        }
        that.$confirm({
          title: '确认',
          content: h => `将有${param.length}个标签被打印(注意：此功能会分拣并打印所有未分拣的商品)，确定全部打印？`,
          okText: '确定',
          okType: 'info',
          cancelText: '取消',
          onOk() {
            if (param.length > 0) {
              param = JSON.stringify(param);
              param = {item: param};
              that.rq.post('/merapi/sorting/v2/sorting/batch-sort', param).then(res => {
                var data = res.data;
                if (that.has_check_print) {
                  for (var i = 0; i < data.length; i++) {
                    var url = that.printUrl + '?' + data[i]['url'] + '&type=tag'
                    PrintAccount('批量打印', url, 'print')
                  }
                }
                that.getData('refresh')

              })
            }
          },
          onCancel() {

          },
          class: 'test',
        });
      },
      searchformData(e) {
        this.staticdata = e
        this.getData('refresh')
      },
      sortingAction(item) {
        var data = {
          client: item.client.name,
          id: item.id,
          base_unit: item.base_unit,
          sorting_status: item.sorting_status,
          sorting_status_name: item.sorting_status_name,
          order_sum: item.order_sum,
          sorting_sum: item.sorting_sum,
          stock_status: item.stock_status,
          product_name: item.product_name,
          order_id: item.order_id,
          sortingprint: this.has_check_print
        };
        localStorage.setItem('sortingdatac', JSON.stringify(data))
        this.$router.push({
          name: 'SortOperate',
          query: data
        })
      },
      dialogVisibleClick(e) {
        this.dialogVisible = e
      },
      batchGetId: function () { // 批量操作id生成
        var that = this;
        var data = this.datalist;
        this.batch.sort = [];
        this.batch.print = [];
        if (data.length) {
          data.forEach(function (value) {
            if (value.sorting_status == 0 && !(value.exception_status == 4)) {// 需要去掉订单被删除的
              that.batch.sort.push(value.id);
            }
            if (!(value.exception_status == 4)) {
              that.batch.print.push(value.id);
            }

          });
        }
      },
      getData(e) {
        this.loading = true;
        if (e == 'refresh') {
          this.staticdata.page = 1;
        }
        let p = Object.assign({}, this.staticdata, this.filterForm)
        this.rq.get('/merapi/sorting/v2/sorting/index', {params: p}).then(res => {
          this.otherData = res.data.other
          this.loading = false;
          this.LOCK_STATUS = false;
          this.loadingType = res.data.data.length === this.staticdata.page_size ? 'more' : 'nomore';

          if (e == 'refresh') {
            this.datalist = res.data.data
          } else {
            this.datalist = [...this.datalist, ...res.data.data];
          }

          let that = this;
          this.$nextTick(() => {
            let newList = JSON.parse(JSON.stringify(that.datalist))
            newList.forEach((item, index) => {
              let domContainerWidth = that.$refs['entity-name-container'+index][0].clientWidth;
              console.log(that.$refs['entity-name-container'+index])
              let domTextWidth = that.$refs['entity-name'+index][0].clientWidth;

              console.log(domContainerWidth, domTextWidth)
              if(domTextWidth <= domContainerWidth)
              {
                item.client.nameScale = 'scale(1)';
              }
              else
              {
                let r = domContainerWidth/domTextWidth;
                item.client.nameScale = 'scale('+r+')'
              }
            })
            that.datalist = newList
          })

          this.batchGetId();

        }).catch(err => {
          console.log(err)
          this.loading = false;
          this.LOCK_STATUS = false;
        })
      },
      listenScroll(e) {
        if (this.LOCK_STATUS) return;
        if (e instanceof Event) {
          var el = e.target;
          var scHeight = el.scrollHeight, scTop = el.scrollTop, clHeight = el.clientHeight;
          //距离底部100px时，开始加载数据
          if (scHeight - scTop > clHeight + 20) return;
        }
        this.LOCK_STATUS = true;
        if (this.loadingType === 'nomore' || this.datalist.length === 0) return;
        this.staticdata.page++;
        this.getData();
      },
    }
  }
</script>

<style scoped lang="less">
  .nochoseClick {
    background: #e9e9e9;
    color: #fff;
  }

  .choseClick {
    color: #fff;
    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
  }

  .input-row + .input-row {
    margin-top: 30px;
  }

  /deep/ .ant-drawer-header {
    border-bottom: none;
  }

  .form-title {
    font-size: 20px;

    font-weight: 600;
    color: #919191;
    line-height: 28px;
  }

  .form-input {
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #C6CBD4;

    font-size: 23px;

    font-weight: 600;
    color: #1F3D9E;
  }

  /deep/ .ant-select-selection.ant-select-selection--single {
    height: 100%;
    border: none;
  }

  /deep/ .ant-select-selection__rendered {
    height: 100%;
    border: none;
    display: flex;
    align-items: center;
  }

  .drawer-title-view {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 23px;

      font-weight: 600;
      color: #000000;
      line-height: 32px;
    }

    .btns {
      display: flex;
      align-items: center;

      .title-btn {
        width: 120px;
        height: 80px;
        border-radius: 5px;
        font-size: 20px;

        font-weight: 600;
        color: #1F3D9E;
        border: 1px solid #C6CBD4;
      }

      .title-btn + .title-btn {
        margin-left: 30px;
      }
    }
  }

  .sortPrint {
    .radioStyle {
      border-radius: 100%;
      line-height: 28px;
      margin-right: 10px;
      width: 28px;
      height: 28px;
      font-size: 14px;
    }

    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 19px 29px;
    font-size: 23px;

    font-weight: 600;
    text-align: center;
    color: #000000;
    background: #FFFFFF;
    box-shadow: 0px 2px 11px 0px rgb(177 188 199 / 15%);
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 11px 0px rgba(177, 188, 199, 0.15);
    justify-content: center;
  }

  .out-stock-container {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #F1F2F2;
    overflow: hidden;
    position: relative;

    .header {
      display: flex;
    }

    .soring-info-view {
      height: 96px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      border: 1px solid #6495ED;

      .info-item {
        background-color: #6495ED;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        font-size: 20px;

        font-weight: 600;
        color: #fff;

        .info-item-title {

        }

        .info-item-value {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 28px;
          margin-bottom: 10px;
        }

        .info-item-value-unit {
          margin-left: 5px;
        }
      }

      .info-item + .info-item {
        margin-left: 5px;
      }
    }

    .sorting-detail-view {
      height: 540px;

      &::-webkit-scrollbar { // 直接复制黏贴到样式页.css，完美解决
        display: none;
        /* background-color:transparent; */
      }

      overflow-y: scroll;
      overflow-x: hidden;

      .sorting-detail-item {
        margin-top: 10px;
        overflow: hidden;
        height: 170px;
        background-color: #fff;
        /*box-shadow: 0px 5px 15px 0px rgba(56, 62, 68, 0.13);*/
        border-radius: 5px;
        padding: 15px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: space-between;

        &.item-border {
        border-width: 1px;
        border-style: solid;
      }
      &.border-0 {
        border-color: #BC1D00;
      }
      &.border-1 {
        border-color: #BC1D00;
      }

      &.border-2 {
        border-color: #34BC00;
      }

      &.border-3 {
        border-color: #A6A6A6;
      }

      &.border-4 {
        border-color: #BC7B00;
      }

        .detail-item-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .item-status {
            display: flex;
            align-items: center;
            font-size: 23px;

            font-weight: 600;
            color: #000000;

            & > div {
              width: 20px;
              height: 20px;
              background: #BC1D00;
              border-radius: 20px;
              margin-right: 15px;
            }

            .status-1 {
              background: #BC1D00;
            }

            .status-2 {
              background: #34BC00;
            }

            .status-3 {
              background: #A6A6A6;
            }

            .status-4 {
              background: #BC7B00;
            }

          }

          .out-sign {
            width: 38px;
            height: 38px;
            background: #BC7B00;
            border-radius: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 23px;

            font-weight: 600;
            color: #FFFFFF;
          }
        }

        .entity-name {
          /*overflow: hidden;*/

          /*text-overflow: ellipsis;*/


          display:inline-block;
          line-height:2em;
          transform-origin:left center;
          white-space: nowrap;
          font-size: 23px;

          font-weight: 600;
          color: #0F0F0F;
        }

        .num-info {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & > div {
            font-size: 20px;
            overflow: hidden;

            text-overflow: ellipsis;

            white-space: nowrap;

            font-weight: 600;
            color: #989898;

            & > span:nth-child(1) {
              color: #989898;
            }

            & > span:nth-child(2) {
              color: #BC1D00;
            }
          }
        }

        .border-line {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 4px;
          background: #BC1D00;
        }

        .statusBg-1 {
          background: #BC1D00;
        }

        .statusBg-2 {
          background: #34BC00;
        }

        .statusBg-3 {
          background: #A6A6A6;
        }

        .statusBg-4 {
          background: #BC7B00;
        }

        .mark-view {
          display: flex;
          align-items: center;

          .mark-text {
           overflow: hidden;

            text-overflow: ellipsis;

            white-space: nowrap;
            color: #BC1D00;
          flex: 1;
            /*flex-wrap: nowrap;*/
            /*white-space:nowrap;*/
            /*overflow:hidden;*/
            /*text-overflow:ellipsis;*/
          }
        }
      }


    }
  }

</style>
