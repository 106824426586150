<template>
    <div style="transform-origin: top left;width: 1280px;height: 800px;" :style="'transform:scale('+scalechange+','+scalechange+')'">
    <a-modal dialogClass="dialogClass" centered v-model="visible" :footer="null" :closable="false" :afterClose="dialogClose" >

        <div style="display: flex; flex-direction: column; align-items: center">
            <div class="close-icon" @click="dialogClose" style="align-self: flex-end; margin-bottom: 10px"></div>

            <div class="content-view">
                <div class="content-left">
                    <div class="entity-info">
                        <div class="entity-name">遵义市新蒲新区博雅幼儿园有限责任公司</div>
                        <div class="status">待分拣</div>
                    </div>
                    <div class="prod-info">
                        <div class="prod-name">牛肉</div>
                        <div class="num-info">
                            <span>订购数量：</span>
                            <span>20</span>
                            <span>斤</span>
                        </div>
                        <div class="num-info">
                            <span>称重数量：</span>
                            <span>20</span>
                            <span>斤</span>
                        </div>
                        <a-button class="out-stock-btn">标记缺货</a-button>
                    </div>
                </div>
                <div class="content-right">
                    <div class="keyboard-input">
                        <div class="input-view">
                            <div class="input">{{inputValue}}</div>
                            <a-button @click="deleteAction" class="delete">
                                <img src="@/assets/delete.png"/>
                            </a-button>
                        </div>
                        <div class="keyboard">
                            <a-row :gutter="[1,1]" style="background-color: transparent">
                                <a-col
                                        :span="8"
                                        v-for="item in keyboards">
                                    <a-button @click="keyboardClick(item)" class="keyboard-item"
                                              :class="item === '清空' || item === '完成' ? 'operate-item' : 'num'">{{item}}</a-button>
                                </a-col>
                            </a-row>
                        </div>
                    </div>
                    <div class="operate-view">
                        <a-button class="normal-btn">打印</a-button>
                        <a-button class="normal-btn">定值分拣</a-button>
                        <a-button class="normal-btn">多次分拣</a-button>
                        <a-button class="normal-btn blue-btn">分拣</a-button>
                    </div>
                </div>
            </div>
        </div>

    </a-modal>
    </div>
</template>

<script>
  export default {
    name: "sorting_dialog",
    props: {
      visible: false
    },
    data() {
      return {
        keyboards: ['1','2','3','4','5','6','7','8','9','清空','0','完成'],
        inputValue: '',
        scalechange:1
      }
    },
    mounted() {
        this.scalechange = document.body.clientWidth / 1280;
        window.addEventListener('resize', () => {
            this.scalechange = document.body.clientWidth / 1280;
        });
    },
    watch: {
    },
    methods: {
      dialogClose() {
        console.log('xxxx')
        this.inputValue = ''
        this.$emit('change', false)
      },
      keyboardClick(item) {
        if (item === '清空') {
          this.inputValue = ''
        } else if (item === '完成') {

        } else {
          if ((this.inputValue && this.inputValue !== '') || item !== '0') {
            this.inputValue += item;
          }
        }
      },
      deleteAction() {
        this.inputValue = this.inputValue.slice(0, this.inputValue.length - 1)
      }
    }
  }
</script>
<style>
    .ant-modal-mask{
        height:800px;
    }
</style>
<style scoped lang="less">
    /deep/ .ant-modal-body, /deep/ .ant-modal-content {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }
    /deep/ .ant-modal {
        width: 1030px !important;
        padding-bottom: 0 !important;
    }
    

    .close-icon {
        background-image: url("../assets/close.png");
        background-repeat: no-repeat;
        background-size: 100%;
        width: 70px;
        height: 70px;
    }
    .content-view {
        width: 890px;
        height: 425px;
        background: #F7F7F7;
        box-shadow: 0px 2px 70px 0px rgba(0, 0, 0, 0.09);
        border-radius: 10px;
        display: flex;
        .content-left {
            height: 100%;
            background: #FFFFFF;
            box-shadow: 4px 0px 40px 0px rgba(0, 0, 0, 0.13);
            border-radius: 10px 0px 0px 10px;
            width: 288px;
            padding-left: 32px;
            padding-top: 30px;
            padding-right: 26px;
            padding-bottom: 32px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .entity-info {
                display: flex;
                .entity-name {
                    flex: 1;
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 28px;
                    margin-right: 7px;
                }
                .status {
                    height: 34px;
                    border-radius: 5px;
                    border: 1px solid #BC1D00;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #BC1D00;
                    padding: 0 10px;
                }
            }
            .prod-info {
                .prod-name {
                    font-size: 30px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom: 20px;
                }
                .num-info {
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    &>span:nth-child(2) {
                        color: #BC1D00;
                    }
                }
                .num-info+.num-info {
                    margin-top: 10px;
                }
                .out-stock-btn {
                    margin-top: 24px;
                    width: 120px;
                    height: 80px;
                    background: linear-gradient(133deg, #F5515F 0%, #9F041B 100%);
                    border-radius: 5px;
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                }
            }
        }
        .content-right {
            flex: 1;
            display: flex;
            padding-left: 30px;
            padding-top: 33px;
            padding-bottom: 27px;
            padding-right: 32px;
            justify-content: space-between;
            .keyboard-input {
                width: 384px;
                .input-view {
                    height: 90px;
                    display: flex;
                    width: 100%;
                    background: #FFFFFF;
                    border-radius: 5px;
                    border: 1px solid #C6CBD4;
                    padding-left: 60px;
                    .input {
                        height: 100%;
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 40px;
                        font-family: HelveticaNeue-Bold, HelveticaNeue;
                        font-weight: bold;
                        color: #000000;
                        overflow: scroll;
                        &::-webkit-scrollbar{ // 直接复制黏贴到样式页.css，完美解决
                            display: none;
                            /* background-color:transparent; */
                        }
                    }
                    .delete {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: none;
                        width: 60px;
                        height: 100%;
                    }
                }
                .keyboard {
                    width: 385px;
                    box-shadow: 0px 2px 13px 0px rgba(13, 30, 86, 0.2);
                    border-radius: 5px;
                    margin-top: 15px;
                    background-color: #F1F2F2;
                    .keyboard-item {
                        height: 64px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: none;
                        border-radius: 0;
                        &:hover {
                            z-index: 1000;
                        }
                        &.num {
                            font-size: 40px;
                            font-family: HelveticaNeue;
                            color: #000000;
                        }
                        &.operate-item {
                            font-size: 23px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #2648BD;
                            background: #F1F2F2;
                        }
                    }
                }
            }
            .operate-view {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .normal-btn {
                    width: 120px;
                    height: 80px;
                    border-radius: 5px;
                    border: 1px solid #C6CBD4;
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #2343AE;
                    background-color: transparent;
                }
                .blue-btn {
                    color: #FFFFFF;
                    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
                }
            }
        }
    }
</style>